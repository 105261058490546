import React from 'react';
import Header from './components/Header/Header';
import TopContent from './components/TopContent/TopContent';
import MoreFeatures from './components/MoreFeatures/MoreFeatures';
import Footer from './components/Footer/Footer';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Signup from './components/Signup/Signup';
import Theme from './Theme';

function App() {
  return (
    <Theme>
      <Header />
      <TopContent />
      <MoreFeatures />
      <HowItWorks />
      <Signup />
      <Footer />
    </Theme>
  );
}

export default App;
