import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledDiv = styled.div`
  float: left;
  width: 25%;
  font-size: 66px;
  color: ${props => props.theme.colors.primary};
  line-height: 66px;
  text-align: right;
`;

function MailIcon() {
  return <StyledDiv><FontAwesomeIcon icon={faPaperPlane} /></StyledDiv>;
}

export default MailIcon;
