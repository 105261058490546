import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: inline-block;
  height: 50px;
  margin: 0 5px;
  padding: 15px 20px 0 20px;
  background: ${props => props.theme.colors.secondary};
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  color: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  :hover {
    outline: 0;
    opacity: 0.6;
    color: #fff;
  }
  svg {
    padding-left: 5px;
    vertical-align: middle;
    font-size: 18px;
    line-height: 20px;
  }
`;

function SecondaryButton({ children }) {
  return (
    <StyledDiv className={'btn scroll-link'}>{children}</StyledDiv>
  );
}

export default SecondaryButton;
