import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Input from '../Form/Input';
import Select from '../Form/Select';
import Textarea from '../Form/Textarea';
import Checkbox from '../Form/Checkbox';
import SignupButton from './SignupButton';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

function LtForm({ handleSubmit, submitting }) {
  const initialValues = {
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    country: 'US',
    phone: '',
    url: '',
    pay_to: '',
    tax_class: '',
    first_name: '',
    last_name: '',
    title: '',
    username: '',
    password1: '',
    password2: '',
    timezone: '',
    skype: '',
    question_0: '',
    question_1: '',
    question_2: '',
    question_3: '',
    question_4: '',
    question_5: '',
    question_6: '',
    question_7: '',
    question_8: '',
    question_9: '',
    agree_tos: false,
    organization_id: `${process.env.REACT_APP_ORGANIZATION_ID}`,
  };
  const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    address1: Yup.string().required('Required'),
    address2: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zipcode: Yup.string().required('Required'),
    country: Yup.string(),
    phone: Yup.string().required('Required'),
    url: Yup.string().url().required('Required'),
    pay_to: Yup.string().required('Required'),
    tax_class: Yup.string().required('Required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    title: Yup.string(),
    username: Yup.string().email().required('Required'),
    password1: Yup.string().required('Required'),
    password2: Yup.string().oneOf([Yup.ref('password1'), null], 'Passwords must match'),
    timezone: Yup.string().required('Required'),
    skype: Yup.string(),
    question_0: Yup.string().required('Required'),
    question_1: Yup.string().required('Required'),
    question_2: Yup.string().required('Required'),
    question_3: Yup.string().required('Required'),
    question_4: Yup.string().required('Required'),
    question_5: Yup.string().required('Required'),
    question_6: Yup.string().required('Required'),
    question_7: Yup.string().required('Required'),
    question_8: Yup.string().required('Required'),
    question_9: Yup.string().required('Required'),
    agree_tos: Yup.bool().oneOf([true], 'Must agree to terms before proceeding'),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form>
        <Row className={'contact-box'}>
          <Col sm={6}>
            <h3>Account Information</h3>
            <hr />
            <div className="contact-bottom">
              <Input name={'name'} label={'Name'} placeholder={'Your company name'} />
              <Input name={'address1'} label={'Address'} placeholder={'Your company address'} />
              <Input name={'address2'} placeholder={'A building or suite number'} />
              <Input name={'city'} label={'City'} placeholder={'City name'} />
              <Select name={'state'} label={'State'}>
                <option value={''}>Select a state</option>
                <option value={'AL'}>Alabama</option>
                <option value={'AK'}>Alaska</option>
                <option value={'AZ'}>Arizona</option>
                <option value={'AR'}>Arkansas</option>
                <option value={'CA'}>California</option>
                <option value={'CO'}>Colorado</option>
                <option value={'CT'}>Connecticut</option>
                <option value={'DC'}>District of Columbia</option>
                <option value={'DE'}>Delaware</option>
                <option value={'FL'}>Florida</option>
                <option value={'GA'}>Georgia</option>
                <option value={'HI'}>Hawaii</option>
                <option value={'ID'}>Idaho</option>
                <option value={'IL'}>Illinois</option>
                <option value={'IN'}>Indiana</option>
                <option value={'IA'}>Iowa</option>
                <option value={'KS'}>Kansas</option>
                <option value={'KY'}>Kentucky</option>
                <option value={'LA'}>Lousiana</option>
                <option value={'ME'}>Maine</option>
                <option value={'MD'}>Maryland</option>
                <option value={'MA'}>Massachusetts</option>
                <option value={'MI'}>Michigan</option>
                <option value={'MN'}>Minnesota</option>
                <option value={'MS'}>Mississippi</option>
                <option value={'MO'}>Missouri</option>
                <option value={'MT'}>Montana</option>
                <option value={'NE'}>Nebraska</option>
                <option value={'NV'}>Nevada</option>
                <option value={'NH'}>New Hampshire</option>
                <option value={'NJ'}>New Jersey</option>
                <option value={'NM'}>New Mexico</option>
                <option value={'NY'}>New York</option>
                <option value={'NC'}>North Carolina</option>
                <option value={'ND'}>North Dakota</option>
                <option value={'OH'}>Ohio</option>
                <option value={'OK'}>Oklahoma</option>
                <option value={'OR'}>Oregon</option>
                <option value={'PA'}>Pennsylvania</option>
                <option value={'RI'}>Rhode Island</option>
                <option value={'SC'}>South Carolina</option>
                <option value={'SD'}>South Dakota</option>
                <option value={'TN'}>Tennessee</option>
                <option value={'TX'}>Texas</option>
                <option value={'UT'}>Utah</option>
                <option value={'VT'}>Vermont</option>
                <option value={'VA'}>Virginia</option>
                <option value={'WA'}>Washington</option>
                <option value={'WV'}>West Virginia</option>
                <option value={'WI'}>Wisconsin</option>
                <option value={'WY'}>Wyoming</option>
              </Select>
              <Input name={'zipcode'} label={'Zip Code'} placeholder={'00000'} pattern={'[0-9]*'} />
              <Input name={'country'} label={'Country'} disabled={true} />
              <Input name={'phone'} label={'Phone Number'} placeholder={'000-000-0000'} />
              <Input name={'url'} label={'Website'} type={'url'} placeholder={'Your company\'s URL'} />
              <h3>Payment Information</h3>
              <hr />
              <Select name={'pay_to'} label={'Pay To'}>
                <option value={''}>Where do payments go?</option>
                <option value={'CO'}>Company</option>
                <option value={'MC'}>Main Contact</option>
              </Select>
              <Input name={'tax_class'} label={'Tax Class'} />
            </div>
          </Col>
          <Col sm={6}>
            <h3>User Information</h3>
            <hr />
            <div className="contact-bottom">
              <Input name={'first_name'} label={'First Name'} placeholder={'Your first name'} />
              <Input name={'last_name'} label={'Last Name'} placeholder={'Your last name'} />
              <Input name={'title'} label={'Title'} placeholder={'Your business title'} />
              <Input name={'username'} label={'Email'} placeholder={'Your email address'} />
              <Input name={'password1'} label={'Password'} type={'password'} placeholder={'Create a password'} />
              <Input name={'password2'} type={'password'} placeholder={'Re-type your password'} />
              <Select name={'timezone'} label={'Timzeone'}>
                <option value={''}>Select a timezone</option>
                <option value={'US/Alaska'}>US/Alaska</option>
                <option value={'US/Arizona'}>US/Arizona</option>
                <option value={'US/Central'}>US/Central</option>
                <option value={'US/Eastern'}>US/Eastern</option>
                <option value={'US/Hawaii'}>US/Hawaii</option>
                <option value={'US/Mountain'}>US/Mountain</option>
                <option value={'US/Pacific'}>US/Pacific</option>
              </Select>
              <Input name={'skype'} label={'Skype'} placeholder={'Your Skype account name'} />
              <h3>Additional Questions</h3>
              <hr />
              <Input name={'question_0'} label={'Targeted Countries'} />
              <Input name={'question_1'} label={'Traffic Types'} placeholder={'Display, email, mobile, SEO, etc'} />
              <Input name={'question_2'} label={'Traffic Verticals'} placeholder={'Insurance, gaming, utilities, etc.'} />
              <Textarea name={'question_3'} label={'Top Three Offers'} />
              <Input name={'question_4'} label={`How Did You Hear About ${process.env.REACT_APP_COMPANY}?`} />
              <Textarea
                name={'question_5'}
                label={'Best Phone and Time'}
                placeholder={'Provide the best phone number and time to reach you'}
              />
              <Textarea name={'question_6'} label={'Enter Your Website URL(s)'} />
              <Input name={'question_7'} label={'Contact Reference #1'} />
              <Input name={'question_8'} label={'Contact Reference #2'} />
              <Input name={'question_9'} label={'Contact Reference #3'} />
            </div>
          </Col>
        </Row>
        <Row className={'contact-box'}>
          <Col sm={12}>
            <Checkbox name={'agree_tos'}>
              I agree to the <a href={`${process.env.REACT_APP_TERMS_URL}`} download>terms and conditions</a>.
            </Checkbox>
            <Input name={'organization_id'} type={'hidden'} />
            <SignupButton disabled={submitting} />
          </Col>
        </Row>
      </Form>
    </Formik>
  );
}

export default LtForm;
