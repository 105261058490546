import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  background: ${props => props.theme.colors.primary};
  font-size: 32px;
  color: #fff;
  line-height: 64px;
  -moz-border-radius: 50%; 
  -webkit-border-radius: 50%; 
  border-radius: 50%;
  :after {
    position: absolute; 
    content: ""; 
    top: -7px; 
    left: -7px; 
    width: 82px; 
    height: 82px;
    background: #444; 
    background: rgba(0, 0, 0, 0.1); 
    z-index: -99;
    -moz-border-radius: 50%; 
    -webkit-border-radius: 50%; 
    border-radius: 50%;
  }
`;

function Step({ children }) {
  return <StyledDiv>{children}</StyledDiv>
}

export default Step;
