import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  color: ${props => props.theme.colors.secondary};
  border: 0;
  :focus {
    border-bottom: 1px dotted ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.secondary};
  }
  :hover {
    border-bottom: 1px dotted ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.secondary};
  }
`;

function ContactLink({ children, ...props }) {
  return <StyledLink {...props}>{children}</StyledLink>;
}

export default ContactLink;
