import React from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import { Element, Link } from 'react-scroll';
import Step from './Step';
import './howitworks.css';

function HowItWorks() {
  return (
      <Element name={'how-it-works'}>
        <div className="how-it-works-container section-container section-container-image-bg">
          <Grid>
            <Row>
              <Col sm={12} className={'how-it-works section-description'}>
                <h2>Ready In 4 Steps</h2>
                <div className="divider-1 wow fadeInUp"><span /></div>
              </Col>
            </Row>
            <Row>
              <Col sm={4} smOffset={1} className={'how-it-works-box'}>
                <Step>1</Step>
                <h3>Sign Up</h3>
                <p>
                  Fill out the application form below to get started. Be as complete as possible, it will streamline
                  the entire process.
                </p>
              </Col>
              <Col sm={4} smOffset={2} className={'how-it-works-box'}>
                <Step>2</Step>
                <h3>Confirm Account</h3>
                <p>
                  After we receive your application, we'll reach out to verify your information and confirm your
                  account eligibility.
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={4} smOffset={1} className={'how-it-works-box'}>
                <Step>3</Step>
                <h3>Log In</h3>
                <p>
                  Once eligible, you'll be able to login and access assigned offers and get rolling.
                </p>
              </Col>
              <Col sm={4} smOffset={2} className={'how-it-works-box'}>
                <Step>4</Step>
                <h3>Start Using It</h3>
                <p>
                  Once your traffic is being redirected to our offers you'll be able to log in and get a snapshot of
                  the volume and what converts the best.
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className={'section-bottom-button'}>
                <Link to={'signup-form'} smooth={true}>
                  <SecondaryButton>Get Started</SecondaryButton>
                </Link>
              </Col>
            </Row>
          </Grid>
        </div>
      </Element>
  );
}

export default HowItWorks;
