import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  color: #fff;
  cursor: pointer;
  :focus {
    color: ${props => props.theme.colors.secondary};
    outline: 0;
  }
  :hover {
    color: ${props => props.theme.colors.secondary};
    outline: 0;
  }
`;

function Link({ children, ...props }) {
  return <StyledLink {...props}>{children}</StyledLink>
}

export default Link;
