import React, { useContext, useState } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { Element, scroller } from 'react-scroll';
import './signup.css';
import { BarLoader } from 'react-spinners';
import MailIcon from './MailIcon';
import { ThemeContext } from 'styled-components';
import LtForm from './LtForm';
import LcForm from './LcForm';

function Signup() {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const themeContext = useContext(ThemeContext);

  const handleSubmit = values => {
    setSubmitting(true);
    scroller.scrollTo('signup-form', { smooth: true });
    fetch('https://www.integraltrack.com/api/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(() => setSuccess(true))
        .catch(() => setError(true));
  };

  return (
      <Element name={'signup-form'}>
        <div className="contact-container section-container">
          <Grid>
            <Row>
              <Col sm={6} smOffset={3} className={'contact-box'}>
                <div className="contact-top">
                  <div className="contact-top-left">
                    <h3>Sign Up</h3>
                    <p>Fill in the form below to request our offers:</p>
                  </div>
                  <MailIcon />
                </div>
              </Col>
            </Row>
            {submitting &&
            <Row>
              <Col sm={12}>
                <BarLoader height={3} width={'100%'} color={themeContext.colors.primary}/>
                {success &&
                <div style={{ marginTop: '10px' }}>
                  Your application was submitted successfully! We will contact you shortly to validate your account
                  or get more information from you. Thank you for taking the time to sign up with us!
                </div>
                }
                {error &&
                <div style={{ marginTop: '10px' }}>
                  There was an error submitting your application. Please refresh this page and try again. If you keep
                  having problems email us at the address listed at the bottom of this page.
                </div>
                }
              </Col>
            </Row>
            }
            {process.env.REACT_APP_DOMAIN === 'leadtraction.com' && (
              <LtForm handleSubmit={handleSubmit} submitting={submitting} />
            )}
            {process.env.REACT_APP_DOMAIN === 'lead-cache.com' && (
              <LcForm handleSubmit={handleSubmit} submitting={submitting} />
            )}
          </Grid>
        </div>
      </Element>
  );
}

export default Signup;
