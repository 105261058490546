import React from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { animateScroll } from 'react-scroll';
import Link from './Link';
import './footer.css';
import ContactLink from './ContactLink';

function Footer() {
  return (
      <footer>
        <Grid>
          <Row>
            <Col sm={4} className={'footer-about'}>
              &nbsp;
            </Col>
            <Col sm={4}>&nbsp;</Col>
            <Col sm={4} className={'footer-contact-info'}>
              <h3>Contact Info</h3>
              <p>
                <FontAwesomeIcon icon={faEnvelope} /> Email:&nbsp;
                <ContactLink href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
                  {process.env.REACT_APP_CONTACT_EMAIL}
                </ContactLink>
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className={'footer-copyright'}>
              &copy; {process.env.REACT_APP_COMPANY}, Inc.
            </Col>
            <Col sm={6} className={'footer-menu'}>
              <ul>
                <li><div onClick={() => animateScroll.scrollToTop()}>Top</div></li>
                <li><Link href="/terms">Terms</Link></li>
                <li><Link href="/privacy">Privacy</Link></li>
              </ul>
            </Col>
          </Row>
        </Grid>
      </footer>
  );
}

export default Footer;
