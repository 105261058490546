import React from 'react';
import { useField } from 'formik';

function Textarea({ label, ...props }) {
  const [field, meta] = useField(props);
  const classes = ['form-group', 'has-feedback'];
  if (meta.touched && meta.error) {
    classes.push('has-error');
  }
  else if (meta.touched) {
    classes.push('has-success');
  }

  return (
      <div className={classes.join(' ')}>
        {label && <label htmlFor="contact-email">{label}:</label>}
        <textarea {...field} {...props} className="contact-email form-control" />
        {meta.touched && meta.error && <span className="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true" />}
        {meta.touched && meta.error && <span className={'help-block'}>{meta.error}</span>}
      </div>
  );
}

export default Textarea;
