import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import Grid from 'react-bootstrap/lib/Grid';
import Col from 'react-bootstrap/lib/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import { Link } from 'react-scroll';
import './topcontent.css';

function TopContent() {
  return (
      <div className="top-content">
        <div className="inner-bg">
          <Grid>
            <Row>
              <Col sm={8} smOffset={2} className={'text'}>
                <h1 className="wow fadeInLeftBig"><strong>PREMIER</strong> AFFILIATE MARKETING</h1>
                <div className="description wow fadeInLeftBig">
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={8} smOffset={2} className={'top-buttons'}>
                <Link to={'signup-form'} smooth={true}>
                  <PrimaryButton>
                    Sign Up Now <FontAwesomeIcon icon={faAngleDown} />
                  </PrimaryButton>
                </Link>
                <Link to={'how-it-works'} smooth={true}>
                  <SecondaryButton>
                    How It Works <FontAwesomeIcon icon={faLightbulb} />
                  </SecondaryButton>
                </Link>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
  );
}

export default TopContent;
