import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.div`
  position: relative;
  display: inline-block;
  height: 50px;
  margin: 0 5px;
  padding: 15px 20px 0 20px;
  background: ${props => props.theme.colors.primary};
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  color: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  :hover {
    outline: 0;
    opacity: 0.6;
    color: #fff;
  }
  :before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    padding-left: 5px;
    vertical-align: middle;
    font-size: 20px;
    line-height: 20px;
  }
`;

function PrimaryButton({ children }) {
  return (
      <StyledButton className={'btn scroll-link'}>{children}</StyledButton>
  );
}

export default PrimaryButton;
