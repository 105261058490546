import React from 'react';
import { ThemeProvider } from 'styled-components';

const ltTheme = {
  colors: {
    primary: '#033895',
    secondary: '#f87b00'
  }
}

const lcTheme = {
  colors: {
    primary: '#343434',
    secondary: '#24947d'
  }
}

function Theme({ children }) {
  const useTheme = process.env.REACT_APP_DOMAIN === 'leadtraction.com' ? ltTheme : lcTheme;
  return <ThemeProvider theme={useTheme}>{children}</ThemeProvider>
}

export default Theme;
