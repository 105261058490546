import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  position: relative;
  width: 100%;
  height: 50px;
  margin: 10px 0 0 0;
  padding: 0 20px;
  vertical-align: middle;
  background: ${props => props.theme.colors.primary};
  border: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  color: #fff;
  -moz-border-radius: 4px; 
  -webkit-border-radius: 4px; 
  border-radius: 4px;
  text-shadow: none;
  -moz-box-shadow: none; 
  -webkit-box-shadow: none; 
  box-shadow: none;
  -o-transition: all .3s; 
  -moz-transition: all .3s; 
  -webkit-transition: all .3s; 
  -ms-transition: all .3s; 
  transition: all .3s;
  :hover {
    opacity: 0.6;
    color: #fff;
  }
  :active {
    outline: 0;
    opacity: 0.6;
    color: #fff;
    -moz-box-shadow: none; 
    -webkit-box-shadow: none; 
    box-shadow: none;
  }
  :before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background: rgba(0, 0, 0, 0.1);
  }
`;

function SignupButton(props) {
  return <StyledButton type={'submit'} {...props}>Submit</StyledButton>;
}

export default SignupButton;
