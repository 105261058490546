import React from 'react';
import { useField } from 'formik';

function Checkbox({ children, ...props }) {
  const [field, meta] = useField(props);
  const classes = ['checkbox'];
  if (meta.touched && meta.error) {
    classes.push('has-error');
  }
  else if (meta.touched) {
    classes.push('has-success');
  }

  return (
      <div className={classes.join(' ')}>
        <label>
          <input type={'checkbox'} {...field} {...props} /> {children}
        </label>
        {meta.touched && meta.error && <span className={'help-block'}>{meta.error}</span>}
      </div>
  );
}

export default Checkbox;
