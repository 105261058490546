import React from 'react';
import Navbar from 'react-bootstrap/lib/Navbar';
import { animateScroll } from 'react-scroll';
import './header.css';

function Header() {
  const nbclass = process.env.REACT_APP_DOMAIN === 'leadtraction.com' ? 'navbar-brand-lt' : 'navbar-brand-lc';
  return (
      <Navbar fixedTop={true}>
        <Navbar.Header>
          <Navbar.Brand className={nbclass}>
            <div onClick={() => animateScroll.scrollToTop()}>{process.env.REACT_APP_COMPANY}, Inc</div>
          </Navbar.Brand>
        </Navbar.Header>
      </Navbar>
  );
}

export default Header;
