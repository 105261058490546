import React from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { Link } from 'react-scroll';
import './morefeatures.css';

function MoreFeatures() {
  return (
      <>
        <div className="more-features-2-container section-container section-container-gray-bg">
          <Grid>
            <Row>
              <Col sm={6} className={'more-features-2-box'}>
                <h3>Why work with {process.env.REACT_APP_COMPANY}?</h3>
                <p>
                  {process.env.REACT_APP_COMPANY} provides unique online marketing solutions for our advertisers
                  and publishers. We offer CPA, Rev-Share, Co-Reg and ping-tree solutions. We provide comprehensive
                  tracking and reporting to suit your optimization needs. We're well rounded to cater to your specific
                  needs.
                </p>
              </Col>
              <Col sm={6} className={'more-features-2-box more-features-2-box-right'}>
                <h3>We have many offers to choose from:</h3>
                <ul>
                  <li><FontAwesomeIcon icon={faCheck} /> Education and career placement</li>
                  <li><FontAwesomeIcon icon={faCheck} /> Pay day and personal loans</li>
                  <li><FontAwesomeIcon icon={faCheck} /> Auto finance, insurance, and warranty</li>
                  <li><FontAwesomeIcon icon={faCheck} /> Plus many more!</li>
                </ul>
                <Link to={'signup-form'} smooth={true}>
                  <PrimaryButton>
                    Sign Up Now <FontAwesomeIcon icon={faAngleDown} />
                  </PrimaryButton>
                </Link>
              </Col>
            </Row>
          </Grid>
        </div>
      </>
  );
}

export default MoreFeatures;
